<template>
<div class="business-followup">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="business-followup-cont">
    <div style='padding:20px;'>
      <div class="search-box">
        <div class=search-item>
          <p class="search-title">批次：</p>
          <div class="batch-list">
            <el-alert v-if="searchForm.province_id && batchList.length === 0" title="当前区域没有跟进批次，点击创建跟进批次去创建跟进批次"
                      type="warning" close-text="去创建" @close="createFollowUpBatches()">
            </el-alert>
            <template v-else>
              <div class="batch-item" :class="item.id === searchForm.followup_batch_id ? 'batch-active' : ''"
                   v-for="item in batchList" :key="item.id" @click="changeBatch(item.id)">{{ item.name }}</div>
            </template>
          </div>
        </div>
        <div class=search-item>
          <p class="search-title">状态：</p>
          <div class="search-content" :class="item.id === searchForm.status ? 'content-active' : ''" v-for="item in statusesList" :key="item.id" @click="changeStatus(item.id)">{{ item.name }}</div>
        </div>
      </div>
      <el-table style="margin-top: 20px;" size="small" :data="teacherList" :header-cell-style="headerCellStyle" border>
        <el-table-column label="组织类型"  width="80">
          <template v-slot='{row}'>
            {{ siteTypes[row.site.type] }}
          </template>
        </el-table-column>
        <el-table-column label="组织名称" prop="site.name"></el-table-column>
        <el-table-column label="注册用户" prop="user.nickname">
          <template v-slot='{row}'>
            {{ row.user.realname || row.user.nickname }}
          </template>
        </el-table-column>
        <el-table-column label="联系方式" width="150" prop="user.mobile"></el-table-column>
        <el-table-column label="角色" width="150" prop="rolesStr">

        </el-table-column>
        <el-table-column label="注册时间" width="150" >
          <template v-slot='{row}'>
            {{ util.timeFormatter(new Date(+row.user.created_at * 1000),'yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="150">
          <template v-slot='{row}'>
            <el-select v-model="row.status" size="mini" @change="updateFollowStatus($event,row.id)">
              <el-option v-for="s in statusesList.filter(e=>e.id!=='-1')" :key="s.id" :label="s.name" :value="s.id"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="跟进内容" prop="followup.content"></el-table-column>
        <el-table-column label="跟进时间" width="150">
          <template v-slot='{row}'>
            {{ row?.followup ? util.timeFormatter(new Date(+row?.followup?.created_at * 1000),'yyyy-MM-dd hh:mm:ss') : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template v-slot='{ row }'>
            <el-button type="primary" size="mini" @click="followUp(row)">跟进</el-button>
            <el-button type="primary" size="mini" @click="editUser(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <MyPagin :pagination="pagination" @currentChange="currentChange" @sizeChange="sizeChange"></MyPagin>
    </div>
    <el-drawer :visible.sync='followDrawer' :size="800" @close="followDrawerClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>跟进</div>
        <div class='drawer-title-btns'></div>
      </div>
      <div style='padding:20px;'>
        <el-form ref="formRef" :model="followForm" label-width="80px">
          <el-form-item label="跟进内容">
            <el-input type="textarea" v-model="followForm.content" :rows="3" placeholder="请输入跟进备注"></el-input>
          </el-form-item>
          <el-form-item style="text-align: end;">
            <el-button type="primary" size="small" @click="createFollowUp()">确定</el-button>
          </el-form-item>
        </el-form>
        <Timeline class="timeline">
          <TimelineItem v-for="item in recordsList" :key="item.id">
            <p class="time">{{ util.timeFormatter(new Date(+item.created_at * 1000),'yyyy-MM-dd hh:mm:ss') }} <span>|</span>跟进人：{{ item?.user?.nickname }}（{{ item?.user?.id
              }}）<span>|</span>创建人：{{ item?.creator?.nickname }}（{{ item.creator.id }}）</p>
            <div class="content-wrap">
              <p class="content">{{ item.content }}</p>
              <div>
                <el-button type="warning" size="mini" @click="editRecord(item)">编辑</el-button>
                <el-button type="danger" size="mini" @click="deleteRecord(item)">删除</el-button>
              </div>
            </div>
          </TimelineItem>
        </Timeline>
      </div>
    </el-drawer>
    <el-drawer :visible.sync='editDrawer' @close="editDrawerClose()">
      <div class='drawer-title' slot='title'>
        <div class='drawer-title-text'>编辑跟进记录</div>
        <div class='drawer-title-btns'></div>
      </div>
      <div style='padding:20px;'>
        <el-form ref="editFormRef" :model="editForm" label-width="80px">
          <el-form-item label="跟进内容">
            <el-input type="textarea" v-model="editForm.content" :rows="3" placeholder="请输入跟进备注"></el-input>
          </el-form-item>
          <el-form-item style="text-align: end;">
            <el-button type="primary" size="small" @click="updateFollowUp()">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
  <CreateUser :isManager="false" :userModal="userModal" :userId="curData.id" :roleList="roleList" :statusList="statusList" @close="closeModal"></CreateUser>
</div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, watch } from 'vue';
import { MessageBox } from "element-ui";
import util from "@/utils/tools.js";
import CreateUser from '@/components/createUser';

// 组件实例（相当于this）
const instance = getCurrentInstance().proxy;


const searchForm = reactive({
  // 批次id
  followup_batch_id: "",
  // 状态
  status:"-1"
});

// 批次列表数据
const batchList = ref([]);
// 获取批次列表
function getBatchList() {
  instance.api.customer.businessOpportunityBatchList({
    site_id: "-1",
  }).then(res => {
    batchList.value = res.list || [];
    if (batchList.value.length > 0) {
      searchForm.followup_batch_id = batchList.value[0].id;
      // 拿到批次id再获取老师列表
      getBatchTeacherList();
    } else {
      teacherList.value = [];
    }
  });
}
getBatchList();

// 切换批次
function changeBatch(id) {
  searchForm.followup_batch_id = id;
  getBatchTeacherList();
}

// 表头样式
const headerCellStyle = {
  backgroundColor: "#F8F8F9",
  color: "#666",
  fontWeight: "bold"
}
// 教师列表
const teacherList = ref([]);
// 教师状态对象
const statusesObj = ref({});
// 教师状态列表
const statusesList = ref([]);
let siteTypes = ref({});
// 获取教师列表
function getBatchTeacherList() {
  instance.api.customer.createFollowItemList({
    ...searchForm,
    page: pagination.page,
    page_size: pagination.pageSize
  }).then(res => {
    pagination.total = Number(res.count);
    statusesObj.value = res.statuses || {};
    const arr=[];
    for(let key in res.statuses){
      arr.push({
        id:key,
        name:res.statuses[key]
      });
    }
    arr.unshift({
      id:"-1",
      name:"全部"
    });
    statusesList.value=arr;
    teacherList.value = res.list || [];
    siteTypes.value = res.site_types || {};
    teacherList.value.forEach((item)=>{
      item.rolesStr = item.roles.map((item)=>{
        return item.role.description;
      }).join(",");
    })
  });
}

// 改变状态
function changeStatus(id){
  searchForm.status=id;
  getBatchTeacherList();
}


// 分页器数据
const pagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
});
function currentChange(page) {
  pagination.page = page;
  getBatchTeacherList();
}
function sizeChange(size) {
  pagination.pageSize = size;
  getBatchTeacherList();
}


// 跟进表单
const followForm = reactive({
  // 组织id
  // followup_site_id: props.organizationId,
  // 用户id
  followup_user_id: "",
  content: "",
  followup_batch_id: "",
  followup_item_id: ""
});
// 跟进抽屉可见性
const followDrawer = ref(false);
function followDrawerClose() {
  getBatchTeacherList();
}
// 跟进
function followUp(row) {
  followForm.followup_user_id = row.user.id;
  followForm.followup_batch_id = row.followup_batch_id;
  followForm.followup_item_id = row.id;
  followDrawer.value = true;
  getRecordsList();
}

const recordsList = ref([]);
// 获取跟进记录列表
function getRecordsList() {
  instance.api.customer.customerFollowList(followForm).then(res => {
    recordsList.value = res.list || [];
  });
}
// 创建跟进记录
function createFollowUp() {
  if (!followForm.content) {
    instance.$Message.error("请输入跟进内容");
    return;
  }
  instance.api.customer.customerFollowCreate(followForm).then(() => {
    instance.$Message.success("跟进成功");
    followForm.content = "";
    getRecordsList();
  });
}
const editForm = reactive({
  // 组织id
  id: "",
  content: "",
  // 用户id
  followup_user_id: ""
});

// 更新跟进状态
function updateFollowStatus(status,id){
  instance.api.customer.updateFollowStatus({
    status,
    id
  });
}

// 编辑跟进记录
function editRecord(item) {
  console.log(item);
  editForm.id = item.id;
  editForm.followup_user_id = item.user_id;
  editDrawer.value = true;
}
// 更新
function updateFollowUp() {
  if (!editForm.content) {
    instance.$Message.error("请输入跟进内容");
    return;
  }
  instance.api.user.updateFollow(editForm).then(() => {
    editDrawer.value = false;
    getRecordsList();
    instance.$Message.success("编辑成功");
  });
}
// 删除跟进记录
function deleteRecord(item) {
  MessageBox.confirm('该操作不可逆是否继续', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    instance.api.user.deleteFollow({
      id: item.id
    }).then(() => {
      getRecordsList();
      instance.$Message.success("删除成功");
    });
  });
}

// 编辑跟进抽屉可见性
const editDrawer = ref(false);
function editDrawerClose() {
  editForm.id = "";
  editForm.content = "";
  editForm.followup_user_id = "";
}

// 管理跟进抽屉课件性
const batchManageDrawer = ref(false);

let userModal = ref(false);
let curData = ref({});
const editUser = (data)=>{
  userModal.value = true;
  curData.value = data.user;
}

const closeModal = ()=> {
  userModal.value = false;
  getBatchTeacherList();
};

let roleList = ref([]);
let statusList = ref([]);
const getUserForm = ()=>{
  instance.api.user.userForm().then(res => {
    for(let name in res.roles){
      roleList.value.push({
        value:name,
        label:res.roles[name]
      });
    }
    for(let name in res.statuses){
      statusList.value.push({
        value:name,
        label:res.statuses[name]
      });
    }
  });
}
getUserForm();

</script>

<style scoped lang="scss">
.business-followup{
  padding: 20px;
  .business-followup-cont{
    background-color: #FFFFFF;
    padding: 20px;
  }
}
.search-box {
  .search-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 20px;

    .search-title {
      font-weight: bold;
      flex-shrink: 0;
    }
    .search-content{
      font-size: 16px;
      margin-right: 10px;
      cursor: pointer;
      padding: 3px 5px;
    }
    .content-active {
      background-color: #409EFF;
      color: white;
      border-radius: 4px;
      padding: 3px 5px;
    }

    .batch-list {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;

      .batch-item {
        font-size: 16px;
        margin-right: 10px;
        cursor: pointer;
        padding: 3px 5px;
      }

      .batch-active {
        background-color: #409EFF;
        color: white;
        border-radius: 4px;
        padding: 3px 5px;
      }
    }
  }
}

.timeline {
  font-size: 14px;

  .time {
    font-size: 14px;
    margin-bottom: 10px;

    >span {
      padding: 0 20px;
    }
  }

  .content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      font-size: 14px;
      line-height: 26px;
      white-space: pre-wrap;
    }
  }
}
</style>
